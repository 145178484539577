@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 0%;
    --foreground: 0 0% 100%;
    --card: 240 6% 6%;
    --card-foreground: 0 0% 100%;
    --popover: 240 6% 6%;
    --popover-foreground: 0 0% 100%;
    --panel: 240 2% 8%;
    --panel-foreground: 0 0% 100%;
    --primary: 28 100% 50%;
    --primary-foreground: 0 0% 100%;
    --primary-subtle: 28 100% 32%;
    --alternative: 240 100% 50%;
    --alternative-foreground: 0 0% 100%;
    --secondary: 240 4% 14%;
    --secondary-foreground: 0 0% 83%;
    --muted: 240 4% 24%;
    --muted-foreground: 240 5% 65%;
    --accent: 240 4% 14%;
    --accent-foreground: 0 0% 96%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 93% 94%;
    --warning: 49 96% 40%;
    --warning-foreground: 49 100% 90%;
    --audio-blue: 184 64% 10%;
    --border: 240 4% 14%;
    --input: 0 0% 0%;
    --ring: 28 100% 50%;
    --radius: 0.5rem;
  }

  * {
    @apply border-border focus-visible:outline-none lowercase;
  }

  svg {
    stroke-width: 1 !important;
  }

  html {
    @apply font-sans antialiased;
  }

  body {
    @apply bg-background text-foreground overflow-y-auto;
    font-size: 16px;
  }

  .scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

@layer utilities {
  a[href] {
    @apply normal-case;
  }
  button {
    @apply lowercase;
  }

  input,
  code,
  textarea {
    @apply normal-case;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  aspect-ratio: 0.75;
  --c: no-repeat linear-gradient(hsl(var(--foreground)) 0 0);
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  animation: l7 1s infinite linear alternate;
}
@keyframes l7 {
  0% {
    background-size: 20% 50%, 20% 50%, 20% 50%;
  }
  20% {
    background-size: 20% 20%, 20% 50%, 20% 50%;
  }
  40% {
    background-size: 20% 100%, 20% 20%, 20% 50%;
  }
  60% {
    background-size: 20% 50%, 20% 100%, 20% 20%;
  }
  80% {
    background-size: 20% 50%, 20% 50%, 20% 100%;
  }
  100% {
    background-size: 20% 50%, 20% 50%, 20% 50%;
  }
}

.select-none-webkit {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}
